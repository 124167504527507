<template>
  <div v-if="contents" class="dp-tabs">
    <DpHeaderTab
      v-for="content in contents"
      :key="content.label"
      :content="content"
      :is-active="getState(content)" />
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';
export default defineComponent({
  components: {
    DpHeaderTab: () => import('@/components/dripPod/header/Tab.vue')
  },

  props: {
    contents: {
      type: Array,
      default: undefined
    }
  },

  setup: (props, context) => {
    const routeName = computed(() => context.root.$route.name);

    const getState = (content) => routeName.value === content?.to;

    return { getState };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-tabs {
  z-index: 100;
  position: fixed;
  top: 48px;
  left: 0;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  background-color: variables.$dpWhite01;
  width: 100%;
  min-width: 320px;
  border-bottom: 1px solid variables.$dpGreyEa;
}
</style>
