var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.contents
    ? _c(
        "div",
        { staticClass: "dp-tabs" },
        _vm._l(_vm.contents, function (content) {
          return _c("DpHeaderTab", {
            key: content.label,
            attrs: { content: content, "is-active": _vm.getState(content) },
          })
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }